import React, { useState, useEffect } from "react";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsExports from "../aws-exports";
import { LoadingButton } from "@mui/lab";

import { signOut, getCurrentUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import { Box, LinearProgress, Snackbar } from "@mui/material";
import { getChatroom } from "../graphql/queries";
import { createChatroom } from "../graphql/mutations";

Amplify.configure(awsExports);
const client = generateClient();

const Dashboard = ({ setLoggedIn }) => {
  const [currentId, setCurrentId] = useState("");
  const [chatRoomUrl, setChatRoomUrl] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetchUrl();
  }, []);

  const fetchUrl = async () => {
    try {

      setIsFetching(true);
      const { userId } = await getCurrentUser();

      const url = `${window.location.origin}${process.env.PUBLIC_URL}/#/chat/${userId}`;
      
      setCurrentId(userId);
      setChatRoomUrl(url);

      // Check if chatroom exists
      const chatroom = await client.graphql({
        query: getChatroom,
        variables: {
          adminId: userId,
        },
      });

      // console.log(chatroom);
      // Create if not exists;
      const data = chatroom.data.getChatroom;
      if (data === null) {
        try {
          await client.graphql({
            query: createChatroom,
            variables: {
              input: {
                adminId: userId,
              },
            },
          });
        } catch (error) {
          console.error(error);
        }
      }
    } catch (err) {
      console.error(err);
    }
    finally {
      setIsFetching(false);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    await signOut();
    setLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    setIsLoading(false);
    navigate(`/login`);
  };

  const handleRedirect = () => {
    navigate(`/chat/${currentId}`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(chatRoomUrl);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="wrapper-primary">
      <div className="primary" id="dashboard">
        <div className="main-content">
          <img
            className="logo"
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt=""
          />

          <div className="url-desc">URL</div>
          <Box className="url-wrapper">
            <div className="chat-url">
              {
                <>
                  <input
                    className="url-box"
                    onClick={handleRedirect}
                    readOnly
                    value={chatRoomUrl}
                  ></input>
                </>
              }
              <div className="btn-box" onClick={copyToClipboard}>
                <img
                  className="copy-btn"
                  src={`${process.env.PUBLIC_URL}/copy.svg`}
                  alt=""
                />
              </div>
            </div>
            <Box>
              { 
                isFetching ??
                <LinearProgress className="loading-bar" loading={isFetching} />
              }
            </Box>
          </Box>

          <div className="main-buttons">
            <button className="primary-button" onClick={copyToClipboard}>
              copy
            </button>

            <LoadingButton
              variant="contained"
              className={"secondary-button"}
              onClick={handleLogout}
              loading={isLoading}
              sx={{
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "#000000",
                },
              }}
            >
              {!isLoading && "logout"}
            </LoadingButton >
          </div>

          <Snackbar
            className="snackbar"
            open={snackbarOpen}
            autoHideDuration={1000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{
              textAlign: "center",
            }}
            message="URL copied to clipboard"
          />
        </div>

        <img
          className="peek"
          src={`${process.env.PUBLIC_URL}/peek.png`}
          alt=""
        />
      </div>
    </div>
  );
};

export default Dashboard;
