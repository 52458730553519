import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsExports from "../aws-exports";

import { onCreateMessage } from "../graphql/subscriptions";
import { listMessages } from "../graphql/queries";

import { getCurrentUser } from "aws-amplify/auth";
import Loading from "./Loading";

Amplify.configure(awsExports);
const client = generateClient();

const Chatroom = () => {
  const [messages, setMessages] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {

        const { userId } = await getCurrentUser();
        setAdminId(userId);

        const messageData = await client.graphql({
          query: listMessages,
          variables: {
            filter: {
              adminId: { eq: userId },
            },
          },
        });

        const sortedMessages = messageData.data.listMessages.items.sort(
          function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          }
        );
        setMessages(sortedMessages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, []);

  useEffect(() => {
    const subscription = client.graphql({ query: onCreateMessage }).subscribe({
      next: (eventData) => {
        const newMsg = eventData.data.onCreateMessage;
        
        if (newMsg.adminId === adminId) {
          setMessages((prevMessages) => [...prevMessages, newMsg]);
        }
      },
      error: (error) => console.warn(error),
    });
    return () => subscription.unsubscribe();
  }, [adminId]);

  const makeLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const toHome = () => {
    window.open("https://lp.shyyy.me", "_blank").focus();
  };

  const downloadCSV = () => {
    const csvRows = [
      ["createdAt", "messages"],
      ...messages.map((msg) => [
        new Date(msg.createdAt).toLocaleString(),
        msg.content
      ]),
    ];

    const csvContent = csvRows
      .map((row) => row.map((item) => `"${item}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `shyyy_messages.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (loading) return <Loading/>
  else return (
    <div className="wrapper-secondary">
      <div className="secondary" id="chatroom">
        <div className="messages">
          {messages.map((msg) => (
            <div key={msg.id} className="msg-bubble">
              <div className="bubble-tail" />
              <div
                dangerouslySetInnerHTML={{
                  __html: makeLinks(msg.content),
                }}
              />
            </div>
          ))}
        </div>

        <div className="chatroom-menu">
          <div className="chatroom-content">
            <button className="chat-btn logo-box" onClick={toHome}>
              <img
                className="logo-btn"
                src={`${process.env.PUBLIC_URL}/logo.svg`}
                alt=""
              />
            </button>
            <button onClick={downloadCSV} className="chat-btn download-box">
              download
              <img
                className="download-btn"
                src={`${process.env.PUBLIC_URL}/download.svg`}
                alt=""
              />
            </button>
          </div>

          <div className="chatroom-desc">24時間で消えます</div>
        </div>
      </div>
    </div>
  );
};

export default Chatroom;
