import React, { useState, useEffect } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { LoadingButton } from "@mui/lab";

import { Snackbar } from "@mui/material";
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { useNavigate } from "react-router-dom";

Amplify.configure(awsExports);

const Login = ({ setLoggedIn }) => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {

    const savedLoginState = localStorage.getItem("isLoggedIn");
    if (savedLoginState === "true") {
      setLoggedIn(true);
    }
  }, [setLoggedIn]);

  const handleSignIn = async () => {
    try {

      setIsLoading(true);
      
      const { isSignedIn } = await signIn({
        username : email,
        email,
        password,
      });
      setLoggedIn(isSignedIn);

      if (!isSignedIn) {
        const { isSignedIn } = await confirmSignIn({
          challengeResponse: password,
        });
        setLoggedIn(isSignedIn);
      }

      if (isSignedIn) {
        localStorage.setItem("isLoggedIn", "true");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }

  };

  const redirectToRegister = async () => {
    navigate("/register")
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="wrapper-primary">
      <div className="primary" id="login">
        <div className="main-content">
          <img
            className="logo"
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt=""
          />
          <div className="login-form">
            <input
              className="textbox"
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="textbox"
              type="password"
              placeholder="pass"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="main-buttons">
            <LoadingButton
              variant="contained"
              className={"primary-button"}
              onClick={handleSignIn}
              loading={isLoading}
              sx={{
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "#FFFFFF",
                },
              }}
            >
              {!isLoading && "login"}
            </LoadingButton>

            <button className="secondary-button" onClick={redirectToRegister}>
              create a new account
            </button>
          </div>
        </div>

        <Snackbar
          className="snackbar"
          open={snackbarOpen}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            textAlign: "center",
          }}
          message={errorMessage}
        />

        <img
          className="peek"
          src={`${process.env.PUBLIC_URL}/peek.png`}
          alt=""
        />
      </div>
    </div>
  );
};

export default Login;

