import React, { useState, useEffect } from "react";
import { signUp, signIn } from "aws-amplify/auth";
import { LoadingButton } from "@mui/lab";
import { Snackbar } from "@mui/material";

import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { useNavigate } from "react-router-dom";

Amplify.configure(awsExports);

const Register = ({ setLoggedIn }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const savedLoginState = localStorage.getItem("isLoggedIn");
    if (savedLoginState === "true") {
      setLoggedIn(true);
    }
  }, [setLoggedIn]);

  const handleSignUp = async () => {
    try {
      if(confirmPassword !== password) {
        throw new Error("Password did not match");
      }
      setIsLoading(true);
      const { isSignUpComplete } = await signUp({
        username: email,
        password,
        email,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true,
        },
      });

      if (isSignUpComplete) {
        const { isSignedIn } = await signIn({
          username : email,
          email,
          password,
        });

        setLoggedIn(isSignedIn);
        localStorage.setItem("isLoggedIn", "true");
      }

    } catch (error) {
      console.error(error);

      setErrorMessage(error.message);
      setSnackbarOpen(true);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const redirectToLogin = async () => {
    navigate("/login");
  };

  return (
    <div className="wrapper-primary">
    <div className="primary" id="login">
      <div className="main-content">
        <img className="logo" src={`${process.env.PUBLIC_URL}/logo.svg`} alt="" />
        <div className="login-form">
          <>
            <input
              className="textbox"
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="textbox"
              type="password"
              placeholder="pass"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              className="textbox"
              type="password"
              placeholder="confirm pass"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </>
        </div>

        <div className="main-buttons">
          <LoadingButton
            variant="contained"
            className={"primary-button"}
            onClick={handleSignUp}
            loading={isLoading}
            sx={{
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#FFFFFF",
              },
            }}
          >
            {!isLoading && "register"}
          </LoadingButton>

          <button className="secondary-button" onClick={redirectToLogin}>
            login to an existing account
          </button>
        </div>
      </div>

      <Snackbar
        className="snackbar"
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          textAlign: "center",
        }}
        message={errorMessage}
      />

      <img className="peek" src={`${process.env.PUBLIC_URL}/peek.png`} alt="" />
    </div>
    </div>
  );
};

export default Register;

