import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsExports from "../aws-exports";
import { createMessage } from "../graphql/mutations";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import {v4 as uuidv4} from 'uuid';

Amplify.configure(awsExports);
const client = generateClient();

const Chatbox = () => {
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const getExpirationTime = () => {
    const SECONDS_IN_AN_HOUR = 60 * 60;
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const expirationTime = secondsSinceEpoch + 24 * SECONDS_IN_AN_HOUR;
    return expirationTime;
  };

  const sendMessage = async () => {
    if (newMessage === "") return;
    try {
      setIsLoading(true);
      await client.graphql({
        query: createMessage,
        variables: {
          input: {
            id: uuidv4(),
            content: newMessage,
            createdAt: new Date(),
            adminId: id,
            expiresAt: getExpirationTime(),
          },
        },
      });
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toHome = () => {
    window.open("https://lp.shyyy.me", "_blank").focus();
  };

  return (
    <div className="wrapper-primary">
    <div className="primary" id="chatbox">
      <div className="chatbox-content">
        <textarea
          className="chatbox-input"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Enter text."
        />
        <div className="chatbox-decoration">
          <div className="chatbox-bubbles">
            <div className="chatbox-bubble bubble-big" />
            <div className="chatbox-bubble bubble-small" />
          </div>
          <img className="catbag" src={`${process.env.PUBLIC_URL}/bag.png`} alt="" />
        </div>
      </div>

      <div className="chatroom-menu">
        <div className="chatroom-content">
          <button className="chat-btn logo-box" onClick={toHome}>
            <img className="logo-btn" src={`${process.env.PUBLIC_URL}/logo.svg`} alt="" />
          </button>

          <LoadingButton
            variant="contained"
            className={"chat-btn download-box"}
            onClick={sendMessage}
            loading={isLoading}
            sx={{
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#FFFFFF",
              },
            }}
          >
            {!isLoading && (
              <>
                send
                <img className="send-btn" src={`${process.env.PUBLIC_URL}/send.svg`} alt="" />
              </>
            )}
          </LoadingButton>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Chatbox;
