import {
  React,
  useState
 } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./App-mobile.css";

import Chat from "./components/Chat";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Register from "./components/Register";

const isAuthenticated = () => localStorage.getItem("isLoggedIn");

const App = () => {

  const [loggedIn, setLoggedIn] = useState(isAuthenticated());

  return (
    <Router>
      <Routes>

        <Route
          path="/login"
          element={
            !loggedIn ? (
              <Login setLoggedIn={setLoggedIn} />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route
          path="/"
          element={
            loggedIn ? (
              <Dashboard setLoggedIn={setLoggedIn} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/register"
          element={
            loggedIn ? (
              <Dashboard setLoggedIn={setLoggedIn} />
            ) : (
              <Register setLoggedIn={setLoggedIn} />
            )
          }
        />

        <Route path="/chat/:id" element={<Chat />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
