/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      adminId
      createdAt
      expiresAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        content
        adminId
        createdAt
        expiresAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatroom = /* GraphQL */ `
  query GetChatroom($adminId: String!) {
    getChatroom(adminId: $adminId) {
      adminId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatrooms = /* GraphQL */ `
  query ListChatrooms(
    $adminId: String
    $filter: ModelChatroomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatrooms(
      adminId: $adminId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        adminId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
