import { React, useEffect, useState } from "react";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsExports from "../aws-exports";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getCurrentUser } from "aws-amplify/auth";
import Chatbox from "./Chatbox";
import Chatroom from "./Chatroom";
import { getChatroom } from "../graphql/queries";

import Loading from "./Loading";


const Chat = () => {

  const [currentId, setCurrentId] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const { id } = useParams();
  const navigate = useNavigate();

  Amplify.configure(awsExports);
  const client = generateClient();

  useEffect(() => {
    const getUserId = async () => {
      try {
        // Check if chatroom exists
        const chatroom = await client.graphql({
          query: getChatroom,
          variables: {
            adminId: id,
          },
        });

        const data = chatroom.data.getChatroom;

        if (data === null) {
          navigate(`/`);
        }

        const { userId } = await getCurrentUser();
        setCurrentId(userId);
      } catch (err) {}
      finally {
        setLoading(false);
      }
    };

    getUserId();
  }, [client, id, navigate]);

  if (loading) {
    return (
      <Loading/>
    );
  }

  if (id === currentId) {
    return <Chatroom />;
  } else {
    return <Chatbox />;
  }
};

export default Chat;
